
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import { fileUpload } from '@/api/base'
import {
    getReportDetail,
    addBill,
    delBill,
    ReportDetailDto, BillDto
} from '@/api/report'
import global from '@/utils/global'
import { ElUploadRequestOptions } from 'element-plus/lib/components/upload/src/upload.type'

export default defineComponent({
    name: 'ReportDetail',
    components: {
        'page-head': pageHead
    },
    data () {
        return {
            data: {} as ReportDetailDto,
            canBill: false,
            showBillEdit: false,
            reportId: Number(this.$route.params.id),
            token: global.token,
            showPreview: false,
            previewList: [] as string[],
            previewIndex: 0
        }
    },
    async created () {
        await this.getReport()
    },
    methods: {
        handlePreview (uploadFile: { url: string | undefined }) {
            // 获取url 在 previewList中的index
            this.previewIndex = this.previewList.findIndex((item) => item === uploadFile.url)
            this.showPreview = true
        },
        async getReport () {
            const result = await getReportDetail({
                reportId: this.$route.params.id as string
            })
            if (result.bill_formatted.length > 0) {
                this.canBill = true
                this.previewList = []
                result.bill_formatted.forEach((item) => {
                    item.files.forEach((file) => {
                        // 插入到previewList
                        this.previewList.push(file.url)
                    })
                })
            }
            this.data = result
        },
        async uploadItem (file: ElUploadRequestOptions, type: string) {
            const FormDatas = new FormData()
            FormDatas.append('iFile', file.file)
            const res = await fileUpload(FormDatas)
            await addBill({
                reportId: this.reportId,
                fileId: res.fileInfo.file_id,
                type: type
            })
            await this.getReport()
            this.$message.success('上传成功')
        },
        async delItem (file: BillDto) {
            await this.$util.confirm('', '确定删除该图片？')
            await delBill({ id: Number(file.id) })
            this.$message.success('删除成功')
        },
        editBill () {
            this.showBillEdit = true
            this.showPreview = false
        }
    }
})
